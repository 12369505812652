.heading{
    text-align: center;
   /* padding: 10px;
    */
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    /* font-size: 100%; */
}

.heading1{
    text-align: center;
    /* font-family: Tungsten A,Tungsten B,Helvetica,Arial,sans-serif; */
    /* font-weight: 700; */
   
    letter-spacing: .03em;
 padding-top: 10px;
    color: #4e738a;
    
}
h1{
    font-size: 3rem;
 
}
html {
    box-sizing: border-box;
  }
  
  *, 
  *:before, 
  *:after {
    box-sizing: inherit;
  }
  
  
  
  .image-grid {
    padding: 12px;
  }
  
  .image-row {
    display: flex;  
  }
  
  .image-row .image {
    margin: 10px;
    height: 350px;  
    
  }
  
  .image { 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3), 
                0 6px 20px rgba(0, 0, 0, 0.15),
                0 6px 20px rgba(0, 0, 0, 0.7);
  }
  
  .image-01 {
    background-image: url('../Image/grid1.jpg'); 
    flex: 2.5;
    object-fit: cover;

  }
  
  .image-02 {
    background-image:  url('../Image/grid2.jpg');
    flex: 1;  
  }
  
  .image-03 {
    background-image:  url('../Image/grid3.jpg');
    flex: 2;  
  }
  
  .image-04 {
    background-image:  url('../Image/grid4.jpg');
    flex: 1;
  }
  
  .image-05 {
    background-image: url('../Image/grid2.jpg');
    flex: 1;
  }
  
  .image-06 {
    background-image: url('https://images.unsplash.com/photo-1604927737867-9f413dac3d08?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDYwfDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60');  
    flex: 2;
  }
  
  
  
  @media screen and (max-width: 400px) {
    .image-row {
      flex-direction: column;
    }
    
    .image-row .image {
      flex-basis: auto;
    }
  }