.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: white;
  z-index: 1;
  margin-top: 10px !important;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #f13d3d transparent #f13d3d transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}





.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: white;
    z-index: 1;
    margin-top: 400px;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #f13d3d transparent #f13d3d transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.swiper{
  height: 10vh !important;
}
