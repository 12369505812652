.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 1.5rem;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.nav-item{
  font-size: 20px;
}

.imgg{
  width: 100%;
  height: 650px;
}
.navbar{
background-color: transparent;
  position: sticky;
  display:block !important;
  color: white;
  /* color: white !important; */
}
.navbar.active{
  background: rgb(238,174,202);
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
}
.navbar {
  color: white !important;
}